<template>
  <div class="main">
    <div style="min-height: 700px; padding: 20px 0; background: #fff">
      <div class="itemName">
        <el-divider>
          <div class="dividerFont">
            <span>消息中心</span>
            <div
              style="width: 60px; height: 2px; background-color: #029aff"
            ></div>
          </div>
        </el-divider>
      </div>

      <div class="formBox">
        <div class="message">
          <div @click="tabInfo(1)">
            <el-badge
              :is-dot="sysMessage.find(i => !i.read) ? true : false"
              class="label"
            >
              <span :class="active == 1 ? 'active' : ''"
                >系统通知</span
              ></el-badge
            >
          </div>
          <span class="label-box"></span>
          <div @click="tabInfo(2)">
            <el-badge
              :is-dot="jobMessage.find(i => !i.read) ? true : false"
              class="label"
            >
              <span :class="active == 2 ? 'active' : ''">消息通知</span>
            </el-badge>
          </div>
        </div>
      </div>

      <div class="line"></div>

      <!-- 内容 -->
      <div class="containBox" v-if="active == 1">
        <div
          class="contain"
          v-for="(item, index) in sysMessage"
          :key="index"
          @click="clickNotice(item)"
        >
          <div class="contain-header">
            <div class="contain-not">
              <img src="@/img/mesg.png" alt="" class="mesg" />
              <span class="contain-notice">系统通知</span>
            </div>

            <div class="contain-time">
              <span>{{ getTime(item.time_long) }}</span>
            </div>
          </div>

          <div class="fair">
            <span>{{ item.content }}</span>
          </div>
        </div>

        <el-empty
          v-if="sysMessage.length == 0"
          description="空空如也"
        ></el-empty>

        <!-- <div style="text-align: center; margin-top: 20px" v-if="sysMessage.length">
          <el-pagination
            :background="true"
            layout="prev, pager, next"
            :total="total"
            :page-size="10"
            @current-change="changePagination"
          >
          </el-pagination>
        </div> -->
      </div>
      <div class="containBox" v-if="active == 2">
        <div
          class="contain"
          v-for="(item, index) in jobMessage"
          :key="index"
          @click="clickNotice(item)"
        >
          <div class="contain-header">
            <div class="contain-not">
              <img src="@/img/yp.png" alt="" class="mesg" />
              <span class="contain-notice">应聘通知</span>
            </div>

            <div class="contain-time">
              <span>{{ getTime(item.time_long) }}</span>
            </div>
          </div>

          <div class="fair">
            <span>{{ item.content }}</span>
          </div>
        </div>

        <el-empty
          v-if="jobMessage.length == 0"
          description="空空如也"
        ></el-empty>

        <!-- <div style="text-align: center; margin-top: 20px" v-if="jobMessage.length">
          <el-pagination
            :background="true"
            layout="prev, pager, next"
            :total="total"
            :page-size="10"
            @current-change="changePagination"
          >
          </el-pagination>
        </div> -->
      </div>

      <!-- <div style="text-align: center; margin-top: 20px">
        <el-pagination
          :background="true"
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          @current-change="changePagination"
        >
        </el-pagination>
      </div> -->
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import { caculateTimeago } from '@/utils/common.js'
export default {
  data() {
    return {
      active: 1,
      total: 1,
      backFlag: false,
      messageList: [], // 二维数组
      curMessageList: [], // 当前循环消息列表，messageList里的某一组
      allMessageList: [],
      // sysMessage: [],
      // jobMessage: [],
    }
  },
  computed: {
    ...mapState([
      'operateList',
      'orgList',
      'isOperatePoint',
      'isOrgRedPoint',
      'wsMessage',
    ]),
    ...mapGetters(['sysMessage', 'jobMessage']),
  },
  created() {
    // this.sysMessage = this.wsMessage.filter(i => i.payload_totype == 'sys')
    // this.jobMessage = this.wsMessage.filter(i => i.payload_totype == 'job')
    // if (this.active == 1) {
    //   this.sysMessage.forEach(i => (i.read = true))
    // } else if (this.active == 2) {
    //   this.jobMessage.forEach(i => (i.read = true))
    // }
    // this.wsMessage = [...this.sysMessage, ...this.jobMessage]
    // this.$store.commit('set_wsMessage', this.wsMessage)
  },
  watch: {
    // wsMessage(val1, val2) {
    //   this.sysMessage = val1.filter(i => i.payload_totype == 'sys')
    //   this.jobMessage = val1.filter(i => i.payload_totype == 'job')
    //   if (this.active == 1) {
    //     this.sysMessage.forEach(i => (i.read = true))
    //   } else if (this.active == 2) {
    //     this.jobMessage.forEach(i => (i.read = true))
    //   }
    // },
    active(val1, val2) {
      // if (val1 == 1) {
      //   this.sysMessage.forEach(i => (i.read = true))
      // } else if (val1 == 2) {
      //   this.jobMessage.forEach(i => (i.read = true))
      // }
      // this.wsMessage = [...this.sysMessage, ...this.jobMessage]
      // this.$store.commit('set_wsMessage', this.wsMessage)
      if (val1 == 1) {
        let newArr = this.wsMessage.map(i => {
          if (i.payload_totype == 'sys') {
            i.read = true
          }
          return i
        })
        this.$store.commit('set_wsMessage', newArr)
      } else if (val1 == 2) {
        let newArr = this.wsMessage.map(i => {
          if (i.payload_totype == 'job') {
            i.read = true
          }
          return i
        })
        this.$store.commit('set_wsMessage', newArr)
      }
    },
  },
  methods: {
    tabInfo(val) {
      this.active = val
    },
    // 翻页
    changePagination(cur) {
      this.curMessageList = this.messageList[cur - 1]
    },
    getTime(time) {
      // return caculateTimeago(time * 1000)
      return caculateTimeago(time)
    },
    clickBack() {
      this.backFlag = false
      if (this.active == 1) {
        this.allMessageList = [...this.operateList]
        this.messageList = this.arrChange(10, this.allMessageList)
        this.curMessageList = this.messageList[0] || []
        this.total = this.operateList.length
      } else if (this.active == 2) {
        this.allMessageList = [...this.orgList]
        this.messageList = this.arrChange(10, this.allMessageList)
        this.curMessageList = this.messageList[0] || []
        this.total = this.orgList.length
      }
    },
    clickNotice(item) {
      // if (this.backFlag) {
      //   return
      // }
      // this.backFlag = true
      // this.getOneMessageList(item)
    },
    // 拉取C2C对话消息
    getOneMessageList(item) {
      let option = {
        conversationID: 'C2C' + item.userProfile.userID,
      }
      let that = this
      that.curMessageList = []
      let promise = this.$tim.getMessageList(option)
      promise.then(function (imResponse) {
        console.log(imResponse, '拉取单个会话列表')
        that.allMessageList = imResponse.data.messageList.reverse() // 消息列表。
        that.total = imResponse.data.messageList.length
        that.messageList = that.arrChange(10, that.allMessageList)
        that.curMessageList = that.messageList[0]
        // 设置已读
        that.setRead(item.userProfile.userID)
      })
    },
    arrChange(num, arr) {
      const newArr = []
      while (arr.length > 0) {
        newArr.push(arr.splice(0, num))
      }
      return newArr
    },
    // 单个会话设置已读
    setRead(id) {
      // 将某会话下所有未读消息已读上报
      let promise = this.$tim.setMessageRead({
        conversationID: 'C2C' + id,
      })
      promise
        .then(function (imResponse) {
          // 已读上报成功，指定 ID 的会话的 unreadCount 属性值被置为0
          console.log(imResponse, '已读上报成功')
        })
        .catch(function (imError) {
          // 已读上报失败
          console.warn('setMessageRead error:已读上报失败', imError)
        })
    },
  },
  filters: {},
}
</script>

<style scoped lang="scss">
::v-deep {
  .el-pagination.is-background .btn-prev {
    /*对下一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .btn-next {
    /*对上一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff; // 进行修改未选中背景和字体
    // color: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: rgb(19, 138, 206); // 进行修改选中项背景和字体
    color: rgb(255, 255, 255);
  }
}
.itemName {
  padding: 0 86px;
  padding-bottom: 22px;
  .dividerFont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 50px;
    span {
      font-size: 24px;
      margin-bottom: 6px;
    }
  }
}

::v-deep .el-tabs {
  margin-top: 10px;
  background-color: #fff;
  .el-tabs__nav-wrap {
    background-color: #fafafa;
    .el-tabs__nav {
      border: none;
      .el-tabs__item {
        border: none;
      }
      .is-active {
        background-color: #fff;
      }
    }
  }
}

.formBox {
  width: 90%;
  background-color: #fff;
  margin: 10px auto;

  .label-box {
    height: 19px;
    border-left: 1px solid #ccc;
    text-align: left;
    margin: 0 30px;
  }
  .message {
    display: flex;
    align-items: center;
    // left: calc(45px + 15px - 10px / 2 + 1px + 0px);
    .label {
      color: #333;
      font-size: 16px;
      font-weight: 400;
      margin: 10px;
      cursor: pointer;
    }
    .mesg-red {
      width: 10px;
      height: 10px;
      margin-top: -10px;
      margin-left: -10px;
      border-radius: 100px;
      background-color: #ff5a5f;
    }
  }
  .active {
    color: #029aff;
  }
}

.line {
  border-top: 1px solid #f4f4f4;
  margin-left: 40px;
  margin-right: 40px;
}

.el-icon-back {
  font-size: 30px;
  color: #999;
  margin: 0 0 20px 55px;
  cursor: pointer;
}
.el-icon-back:hover {
  color: #029aff;
}

.containBox {
  padding-top: 20px;
  overflow-y: auto;
  max-height: 862px;
}

.containBox::-webkit-scrollbar {
  width: 4px;
}
div::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
	background: rgba(196, 196, 196, 0.3);
}
div::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
	border-radius: 0;
	background: #f5f4f9;
}

.contain {
  border-radius: 10px;
  background: #fff;
  box-sizing: border-box;
  border: 1px solid #f0f0f0ff;
  margin: 0px 55px 40px 55px;
  box-sizing: border-box;
  // cursor: pointer;

  .contain-header {
    display: flex;
    justify-content: space-between;
    opacity: 1;
    background: #f5f4f9ff;
    padding-bottom: 10px;
    .contain-not {
      display: flex;
      justify-content: flex-start;
      padding-top: 10px;
      .mesg {
        width: 24px;
        height: 24px;
        padding-left: 20px;
      }
      .contain-notice {
        color: #333;
        font-weight: 500;
        padding-left: 10px;
      }
    }

    .contain-time {
      color: #999;
      font-weight: 500;
      padding-top: 10px;
      padding-right: 20px;
    }
  }

  .fair {
    color: #666;
    font-size: 14px;
    margin: 20px;
    // position: relative;
    display: flex;
    align-items: center;
    span:nth-child(1) {
      width: 96%;
    }
    .badge {
      // position: absolute;
      // right: 0;
      margin-right: 0;
      margin-left: auto;
      width: 20px;
      height: 20px;
      background-color: red;
      border-radius: 50%;
      color: #fff;
      text-align: center;
      line-height: 20px;
      font-size: 12px;
    }
  }
}
</style>
